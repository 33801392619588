import React from 'react'

const Bandsintown = ({ trans }) => {

  return (
    <section id="shows">
      <h2>
        <span className="top">
          <span>{trans.shows}</span>
        </span>
        <span className="bottom">
          <span>{trans.shows}</span>
        </span>
      </h2>

      <div className="bit-widget-container">
        <p className="bit-widget-initializer"

          data-artist-name="id_15499916"

          data-background-color="#ffffff"
          data-separator-color="rgba(255,255,255,1)"
          data-text-color="rgba(0,0,0,1)"
          data-font="Helvetica"
          data-auto-style="true"

          data-local-dates-position="tab"
          data-display-details="false"
          data-display-lineup="false"
          data-display-start-time="true"
          data-social-share-icon="false"
          data-display-limit="10"

          data-date-orientation="horizontal"
          data-date-border-color="#08C3BA"
          data-date-border-width="1px"
          data-date-border-radius="10px"

          data-event-ticket-cta-size="medium"
          data-event-ticket-text="TICKETS"
          data-event-ticket-icon="false"
          data-event-ticket-cta-text-color="rgba(0,0,0,1)"
          data-event-ticket-cta-bg-color="rgba(255,255,255,1)"
          data-event-ticket-cta-border-color="rgba(0,0,0,1)"
          data-event-ticket-cta-border-width="1px"
          data-event-ticket-cta-border-radius="0px"

          data-sold-out-button-text-color="rgba(0,0,0,1)"
          data-sold-out-button-background-color="rgba(255,255,255,1)"
          data-sold-out-button-border-color="rgba(0,0,0,1)"
          data-sold-out-button-clickable="true"

          data-event-rsvp-position="hidden"
          data-event-rsvp-cta-size="medium"
          data-event-rsvp-text="SET REMINDER"
          data-event-rsvp-icon="false"
          data-event-rsvp-cta-text-color="#08C3BA"
          data-event-rsvp-cta-bg-color="#FFFFFF"
          data-event-rsvp-cta-border-color="#08C3BA"
          data-event-rsvp-cta-border-width="1px"
          data-event-rsvp-cta-border-radius="4px"

          data-follow-section-position="hidden"
          data-follow-section-alignment="center"
          data-follow-section-header-text="Get updates on new shows, new music, and more."
          data-follow-section-cta-size="medium"
          data-follow-section-cta-text="FOLLOW"
          data-follow-section-cta-icon="true"
          data-follow-section-cta-text-color="#FFFFFF"
          data-follow-section-cta-bg-color="#08C3BA"
          data-follow-section-cta-border-color="#08C3BA"
          data-follow-section-cta-border-width="0px"
          data-follow-section-cta-border-radius="4px"

          data-play-my-city-position="bottom"
          data-play-my-city-alignment="Center"
          data-play-my-city-header-text="Don’t see a show near you?"
          data-play-my-city-cta-size="medium"
          data-play-my-city-cta-text="REQUEST A SHOW"
          data-play-my-city-cta-icon="false"
          data-play-my-city-cta-text-color="#FFFFFF"
          data-play-my-city-cta-bg-color="rgba(0,0,0,1)"
          data-play-my-city-cta-border-color="rgba(0,0,0,1)"
          data-play-my-city-cta-border-width="0px"
          data-play-my-city-cta-border-radius="0px"

          data-language="fr"
          data-widget-width=""
          data-div-id=""
          data-app-id=""
          data-affil-code="undefined"
          data-bit-logo-position=""
          data-bit-logo-color="rgba(255,255,255,1)"

          data-display-past-dates="false"
        ></p>
      </div>
    </section>
  )
}

export default Bandsintown
