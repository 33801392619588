import applemusic from "../assets/socialIcons/applemusic.svg"
import facebook from "../assets/socialIcons/facebook.svg"
import instagram from "../assets/socialIcons/instagram.svg"
import spotify from "../assets/socialIcons/spotify.svg"
import youtube from "../assets/socialIcons/youtube.svg"
import tiktok from "../assets/socialIcons/tiktok.svg"

export default function StickyFooter() {
    return (
        <>

            <div className="hidden lg:flex bg-white border-t-black border-t-2 p-4 stickyFooterLarge justify-between">
                <div className="flex ">
                    <div className="flex items-center bebas text-xl pr-6 xl:pr-10">
                        <img src={facebook} alt="facebook logo" className="w-8 pr-2" />
                        <a href="https://www.facebook.com/KIRA-OJI-1933874433575695/"  className="text-black text-md">facebook</a>
                    </div>
                    <div className="flex items-center bebas text-xl pr-6 xl:pr-10">
                        <img src={instagram} alt="instagram logo" className="w-8 pr-2" />
                        <a href="https://www.instagram.com/kira_oji/"  className="text-black text-md">Instagram</a>
                    </div>

                    <div className="flex items-center bebas text-xl">
                        <img src={tiktok} alt="TikTok logo" className="w-8 pr-2" />
                        <a href="https://www.tiktok.com/@kiraquirap?lang=fr"  className="text-black text-md">TikTok</a>
                    </div>
                </div>
               
                <div className="flex">
                    <div className="flex items-center bebas text-xl pr-6 xl:pr-10">
                        <img src={spotify} alt="spotify logo" className="w-8 pr-2" />
                        <a href="https://open.spotify.com/artist/5qL0GdnYTCXlr2wJK6XCQm/"  className="text-black text-md">spotify</a>
                    </div>
                    <div className="flex items-center bebas text-xl pr-6 xl:pr-10">
                        <img src={applemusic} alt="applemusic logo" className="w-8 pr-2" />
                        <a href="https://music.apple.com/ca/artist/k-ra/1560376759?l=fr"  className="text-black text-md">Apple Music</a>
                    </div>
                    <div className="flex items-center bebas text-xl pr-6">
                        <img src={youtube} alt="youtube logo" className="w-10 pr-2" />
                        <a href="https://www.youtube.com/channel/UCZwh6Emul46nOWHeMq7hDIg"  className="text-black text-md">Youtube</a>
                    </div>

                </div>
                 
            </div>

        </>
    )
}