import React from "react";
import LogoKiraSmall from "../assets/images/logo/LogoKiraSmall.svg"
import ChangeLanguage from "../ChangeLanguage";

const NavBar = ({ trans, toggleTrans, language }) => {


    function showNavBarMobile() {
        document.getElementById("nav-content").classList.toggle("hidden");
    }

    var prevScrollpos = window.pageYOffset;
        window.onscroll = function() {
    var currentScrollPos = window.pageYOffset;
        if (prevScrollpos > currentScrollPos) {
    document.getElementById("navbar").style.top = "0";
    } else {
    document.getElementById("navbar").style.top = "-80px";
    }
    prevScrollpos = currentScrollPos;
    }

    return (
        <nav className="flex items-center justify-between flex-wrap p-3 lg:p-5 fixed w-full z-10 top-0 topNav bg-white border-b-2 border-black drop-shadow-lg" id="navbar">
            <div className="flex items-center flex-shrink-0 text-white mr-6 lg:hidden">
                <a className="text-white no-underline hover:text-white hover:no-underline" href="/">
                    <img src={LogoKiraSmall} alt="Logo Kira" className="h-6 lg:h-9" />
                </a>
            </div>

            <div className="block lg:hidden">
                <button onClick={() => showNavBarMobile()} id="nav-toggle" className="flex items-center px-3 py-2  text-black hover:text-black">
                    <svg className="fill-current h-5 w-5" viewBox="0 0 20 20" xmlns="http://www.w3.org/2000/svg"><title>Menu</title><path d="M0 3h20v2H0V3zm0 6h20v2H0V9zm0 6h20v2H0v-2z" /></svg>
                </button>
            </div>

            <div className="flex-col w-full h-screen lg:h-auto flex-grow lg:flex-row items-center lg:w-auto hidden lg:block pt-6 lg:pt-0 text-center" id="nav-content">
                <ul className="list-reset lg:h-auto lg:flex justify-between flex-1 items-center bebas">
                    <li>
                        <ul className="lg:flex-row flex flex-col lg:justify-between lg:w-64">
                            <li className=" mt-8 lg:mt-0">
                                <a onClick={() => showNavBarMobile()} className="hover:underline my-5 md:my-0  text-6xl lg:text-2xl text-black tracking-wide" href="#music">{trans.music}</a>
                            </li>
                            <li className=" mt-4 lg:mt-0">
                                <a onClick={() => showNavBarMobile()} className="hover:underline my-5 md:my-0  text-6xl lg:text-2xl text-black tracking-wide" href="#shows">{trans.shows}</a>
                            </li>

                        </ul>
                    </li>

                    <li className="hidden lg:flex ">
                        <a className="text-white no-underline hover:text-white hover:no-underline" href="/">
                            <img src={LogoKiraSmall} alt="Logo Kira" className="h-6 lg:h-9" />
                        </a>
                    </li>
                    <li>
                        <ul className="lg:flex-row flex flex-col lg:justify-between lg:w-64">
                            <li className="mt-8 lg:mt-0">
                                <a onClick={() => showNavBarMobile()} className="hover:underline my-5 md:my-0  text-6xl lg:text-2xl text-black tracking-wide" href="#contact">{trans.contacts}</a>
                            </li>
                            <li className=" mt-8 lg:mt-0">
                                <a rel="noopener noreferrer" target="_blank" href="https://mailchi.mp/coopfauxmonnayeurs/nouvelles-kira" className="bg-black my-4 md:my-0 text-2xl lg:text-lg px-6 py-3 text-white tracking-wide button hover:tracking-widest transition-all ease-in-out">{trans.newsletter}</a>
                            </li>
                        </ul>
                    </li>



                </ul>
            </div>
        </nav>
    )
}

export default (NavBar);