import React from 'react'
import Slide from 'react-reveal/Slide';
import KiraMicro from "../assets/images/KiraMicro.png"

const Newsletter = ({ trans }) => {
  return (
    <section className="flex flex-col items-center mt-24 md:mb-66 md:mt-32 lg:mb-96 mt-66">
      <h2>
        <span className="top">
          <span>{trans.newsletter}</span>
        </span>
        <span className="bottom">
          <span>{trans.newsletter}</span>
        </span>
      </h2>
      <div className="background-clip">
        <div className="newsletter-background">
          <div className="newsletter-slider-top">
            <Slide right>
              <div>
                <img className="newsletter-img" src={KiraMicro} alt="Kira_Micro_Picture" />
              </div>
            </Slide>
          </div>
          <div className="newsletter-slider-bottom">
            <Slide left>
              <div>
                <p className="bebas md:text-6xl text-5xl text-white uppercase mb-14">{trans.newsletterTextCTA}</p>
                <a className="bebas md:text-3xl text-2xl uppercase bg-white text-black pt-2 pb-1 pl-20 pr-20" href='https://mailchi.mp/coopfauxmonnayeurs/nouvelles-kira' target="blank">{trans.newsletterBtnCTA}</a>
              </div>
            </Slide>
          </div>
        </div>
      </div>
    </section>
  )
}

export default Newsletter
