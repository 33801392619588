import React, { useState, useEffect } from "react";
import sanityClient from "../client.js";
import applemusic from "../assets/socialIcons/applemusic.svg"
import facebook from "../assets/socialIcons/facebook.svg"
import instagram from "../assets/socialIcons/instagram.svg"
import spotify from "../assets/socialIcons/spotify.svg"
import youtube from "../assets/socialIcons/youtube.svg"
import tiktok from "../assets/socialIcons/tiktok.svg"


const Hero = ({ language }) => {
    const [hero, setHero] = useState();

    useEffect(() => {
        async function callSanity() {
            await sanityClient
                .fetch(`*[_type == "hero"][0] {
                title{
                    asset->{
                      _id,
                      url
                    },
                    alt
                  },
                "subtitle": subtitle.${language},
                heroImage{
                    asset->{
                      _id,
                      url
                    },
                    alt
                  }          
                }`
                )
                .then((data) => setHero([data]))
                .catch(console.error)
        }
        callSanity()
    }, [language])
    return (
      <>
        <section id="hero">
            {hero && hero.map((hero) => (

                <div className="relative text-center hero-container border-black border-b-2 mb-12" key={hero.heroImage.asset._id}>
                    <img className="hero_img" src={hero.heroImage.asset.url} alt="Artwork" />
                    <img  className="hero_img_txt w-10/12" src={hero.title.asset.url} alt={hero.title.alt} />
                    <h2 className="hero_subtitle text-black">{hero.subtitle}</h2>
                </div>
            ))}
        </section>
        <section className="flex flex-wrap  px-4 w-screen lg:hidden justify-center gap-3 md:gap-8 mb-12">
                  
                  <a href="https://www.facebook.com/KIRA-OJI-1933874433575695/" target="_blank" className="text-black text-md flex bebas items-center">
                  <img src={facebook} alt="facebook logo" className="w-12 pr-2" />
                  <span className="hidden sm:flex lg:hidden text-2xl">Facebook</span>

                  </a>
  
                  <a href="https://www.instagram.com/kira_oji/"  target="_blank" className="text-black text-md bebas flex items-center"> 
                  <img src={instagram} alt="instagram logo" className="w-12 pr-2" />
                  <span className="hidden sm:flex lg:hidden text-2xl">Instagram</span>
                  </a>
  
  
                  <a href="https://www.tiktok.com/@kiraquirap?lang=fr"  target="_blank" className="text-black text-md flex bebas items-center">
                  <img src={tiktok} alt="TikTok logo" className="w-12 pr-2" />
                  <span className="hidden sm:flex lg:hidden text-2xl">TikTok</span>
                  </a>
  
                  <a href="https://open.spotify.com/artist/5qL0GdnYTCXlr2wJK6XCQm/"  target="_blank" className="text-black flex text-md bebas items-center">
                  <img src={spotify} alt="spotify logo" className="w-12 pr-2" />
                  <span className="hidden sm:flex lg:hidden text-2xl">Spotify</span>
                  </a>
  
  
                  <a href="https://music.apple.com/ca/artist/k-ra/1560376759?l=fr"  target="_blank" className="text-black flex text-md bebas items-center">
                  <img src={applemusic} alt="applemusic logo" className="w-12 pr-2" />
                  <span className="hidden sm:flex lg:hidden text-2xl">Apple music</span>
                  </a>
  
  
  
                  <a href="https://www.youtube.com/channel/UCZwh6Emul46nOWHeMq7hDIg"  target="_blank" className="text-black flex text-md bebas items-center">
                  <img src={youtube} alt="youtube logo" className="w-12 pr-2" />
                  <span className="hidden sm:flex lg:hidden text-2xl">Youtube</span>
                  </a>
  
              </section>
      </>  
    )

}
export default Hero