import React, { useState, useEffect } from 'react'
import sanityClient from "../client.js";


const Bandcamp = ({ trans }) => {

  const [tracks, setTracks] = useState([]);

  useEffect(() => {
    sanityClient
      .fetch(`*[_type == "bandcamp"][0..2] | order(_createdAt asc) {
        embed
      }`
      )
      .then((data) => {
        let tracks = []
        for (let i = 0; i < data.length; i++) {
          let embed = data[i].embed.code;
          var regExp = /src\s*=\s*"(.+?)"/;
          var matchSrc = embed.match(regExp);
          tracks.push({ src: (matchSrc && matchSrc[1]) })
        }
        setTracks(tracks)
      })
      .catch(console.error)
  }, [])

  return (
    <section id="music">
      <div className="flex justify-center flex-wrap mb-16">
        <div className="flex-row">
          {tracks && tracks.map((track) => (
            <iframe title="BandCamp player" className="bandcamp-widget-big" src={track.src} seamless key={Math.random()}></iframe>
          ))}
        </div>
      </div>
    </section>
  )
}

export default Bandcamp
