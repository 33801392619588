import React, { useState, useEffect } from "react";
import sanityClient from "../client.js";


const Contacts = ({ trans, language }) => {

  const [contacts, setContacts] = useState();

  useEffect(() => {
    async function callSanity() {
      await sanityClient
        .fetch(`*[_type == "contacts"] {
        "role": role.${language},
        agency,
        phone,
        name,
        email
      }`
        )
        .then((data) => setContacts(data))
        .catch(console.error)
    }
    callSanity()
  }, [language])

  return (
    <section id="contact">
      <h2>
              <span className="top">
                <span>{trans.contacts}</span>
              </span>
              <span className="bottom">
                <span>{trans.contacts}</span>
              </span>
      </h2>

      <div className="flex flex-wrap justify-center ">
        {contacts && contacts.map((contact) => (
            <div key={Math.random()} className="flex flex-col p-4 card space-between border-2 border-black m-2 w-96 h-64">
              <div className="flex-grow">
                <p className="card-role bebas text-xl">{contact.role}</p>
                <p className="card-agency bebas text-6xl text-gray-400 max-w-sm">{contact.agency}</p>
              </div>
              <div >
                <p className="roboto  text-lg">{contact.name}</p>
                <a href={"mailto:" + contact.email} className="text-blue-600 hover:underline" target="_blank">{contact.email}</a>
              </div>
            </div>
          ))}
           
        </div>
    </section>
  )
}

export default Contacts
