import React from "react";
import FauxMonnayeurs from "../assets/images/sponsors/FauxMonnayeurs.svg"
import musicaction from "../assets/images/sponsors/musicaction.svg"
import SODEC from "../assets/images/sponsors/SODEC.svg"
import enregistrementtSodec from "../assets/images/sponsors/enregistrementtSodec.svg"
import spectacleSodec from "../assets/images/sponsors/spectacleSodec.png"
import applemusic from "../assets/socialIcons/applemusic.svg"
import facebook from "../assets/socialIcons/facebook.svg"
import instagram from "../assets/socialIcons/instagram.svg"
import spotify from "../assets/socialIcons/spotify.svg"
import youtube from "../assets/socialIcons/youtube.svg"
import tiktok from "../assets/socialIcons/tiktok.svg"


export default function Footer() {
    return (
        <footer className="bg-black absolute bottom-0 left-0 w-screen">
            <div className="items-center flex gap-10 flex-wrap justify-center w-10/12 bg-white border-black border-2 m-24 p-16 absolute bottom-36 left-0 right-0 mx-auto">
                <img className="min-h-20" src={FauxMonnayeurs} alt="logo COOP les Faux Monnayeurs" />
                <img className="min-h-20" src={musicaction} alt="logo COOP les Faux Monnayeurs" />
                <img className="min-h-20" src={SODEC} alt="logo COOP les Faux Monnayeurs" />
                <img className="min-h-20" src={enregistrementtSodec} alt="logo COOP les Faux Monnayeurs" />
                <img className="min-h-20" src={spectacleSodec} alt="logo COOP les Faux Monnayeurs" />
            </div>

            

            <div className="flex flexwrap absolute bottom-6 grow justify-between px-4 w-screen">
                  
                <a href="https://www.facebook.com/KIRA-OJI-1933874433575695/" target="_blank" className="text-black text-md">
                <img src={facebook} alt="facebook logo" className="w-12 pr-2" />
                </a>

                <a href="https://www.instagram.com/kira_oji/"  target="_blank" className="text-black text-md"> 
                <img src={instagram} alt="instagram logo" className="w-12 pr-2" />
                </a>


                <a href="https://www.tiktok.com/@kiraquirap?lang=fr"  target="_blank" className="text-black text-md">
                <img src={tiktok} alt="TikTok logo" className="w-12 pr-2" />
                </a>

                <a href="https://open.spotify.com/artist/5qL0GdnYTCXlr2wJK6XCQm/"  target="_blank" className="text-black text-md">
                <img src={spotify} alt="spotify logo" className="w-12 pr-2" />
                </a>


                <a href="https://music.apple.com/ca/artist/k-ra/1560376759?l=fr"  target="_blank" className="text-black text-md">
                <img src={applemusic} alt="applemusic logo" className="w-12 pr-2" />
                </a>



                <a href="https://www.youtube.com/channel/UCZwh6Emul46nOWHeMq7hDIg"  target="_blank" className="text-black text-md">
                <img src={youtube} alt="youtube logo" className="w-12 pr-2" />
                </a>

            </div>
        </footer>
    )
}