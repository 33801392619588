import React, { useState, useEffect } from "react";
import sanityClient from "../client.js";
import BlockContent from "@sanity/block-content-to-react";
import Slide from 'react-reveal/Slide';

const About = ({ trans, language }) => {
  const [about, setAbout] = useState();

  useEffect(() => {
    async function callSanity() {
      await sanityClient
        .fetch(`*[_type == "about"][0] {
          "title": title.${language},
          aboutLogo{
            asset->{
              _id,
              url
            },
            alt
          },
          "body": body.${language},
          aboutImage{
            asset->{
              _id,
              url
            },
            alt
          }
        }`
        )
        .then((data) => setAbout([data]))
        .catch(console.error)
    }
    callSanity()
  }, [language])


  return (
    <section id="about">
      <div className="flex justify-center flex-wrap m-5 my-16 lg:mb-96">
        <h2>
          <span className="top">
            <span>{trans.about}</span>
          </span>
          <span className="bottom">
            <span>{trans.about}</span>
          </span>
        </h2>

        {about && about.map((content) => (
          <div className="tracking-wide max-w-7xl text-light-gray flex flex-wrap justify-center" key={Math.random()}>
            <Slide left>
              <div className="flex flex-col about-slider-left">
                <img src={content.aboutLogo.asset.url} alt={content.aboutLogo.alt} className="logo-about" />
                <div className="blockContent-container m-5">
                  <BlockContent
                    blocks={content.body}
                    projectId="wmx4709g"
                    dataset="production" />
                </div>
              </div>
            </Slide>
            <Slide right>
              <div className="flex flex-col about-slider-right place-content-end">
                <img className="about_img" src={content.aboutImage.asset.url} alt={content.aboutImage.alt} />
              </div>
            </Slide>
          </div>
        ))}
      </div>
    </section>
  )
}

export default About
