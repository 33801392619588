import React from "react";
import Video from './Video'
import Bandsintown from './Bandsintown';
import Bandcamp from './Bandcamp';
import Contacts from './Contacts';
import About from './About';
import Newsletter from "./Newsletter";
import Hero from "./Hero";

export default function HomePage({ trans, language }) {
    return (
        <>
            <Hero language={language} />
            <div className="container m-auto">
                <Video trans={trans} />
                <Bandcamp trans={trans} />
                <Bandsintown trans={trans} language={language} />
                <Newsletter trans={trans} />
                <About trans={trans} language={language} />
                <Contacts trans={trans} language={language} />
            </div>

        </>
    )
}